import { Knobs, getKnobs } from '@omni/knobs';
import { HttpMethod } from '@auth0/auth0-angular';

const apiDomain = 'simplystaging.io';
const authDomain = 'simplystaging.io';
const mapGeneratorServiceUrl = 'https://maps.simplysnapcloud.com/api/get-map-image';

export const environment = {
  production: true,
  knobs: getKnobs(Knobs.None),
  apis: {
    gql: `https://api.${apiDomain}/graphql`,
    ws: `wss://ws.${apiDomain}`,
    mapGeneratorService: mapGeneratorServiceUrl,
  },
  auth: {
    clientId: 'hfZaOjxO56OkQqjk1wgJEqCrePk5SKri',
    domain: `login.${authDomain}`,
    scope: 'openid profile email user_id',
    useRefreshTokens: true,
    redirectUri: `${location.origin}/user`,
    target: `${location.origin}/user/select-site`,
  },
  httpInterceptor: {
    allowedList: [
      `https://api.${apiDomain}/graphql`,
      {
        uri: mapGeneratorServiceUrl,
        httpMethod: HttpMethod.Post,
        allowAnonymous: true,
      },
    ],
  },
  firebase: {
    apiKey: 'AIzaSyAtWicwqFCmNeVZ13mjs2aaay2rFW2TaVg',
    authDomain: 'simplysnapcloud-staging.firebaseapp.com',
    projectId: 'simplysnapcloud-staging',
    storageBucket: 'simplysnapcloud-staging.appspot.com',
    messagingSenderId: '30633372829',
    appId: '1:30633372829:web:315a02e5974b4bcfba7874',
    measurementId: 'G-N724XBB2EC',
    functionHost: 'https://us-central1-simplysnapcloud-staging.cloudfunctions.net',
  },
  microFrontends: {
    // @todo: change this to DevIQ's URL once we are settled on it
    energyOverview: 'http://localhost:8080',
  },
};
